import React, { useState, useEffect } from "react";
import "../Styles/CategoriesList.css";
//import categories from './categories.json'
import apiService from "../service/apiService";
import { navigateToUrl } from "single-spa";
export default function CategoriesList() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
      const jsonData = await apiService.getCategories({});
      setCategories(jsonData);
    };
    getCategories();
  }, []);

  const goCategory = (route) => {
    navigateToUrl(`categoria?category=${route.toLowerCase()}`);
  };
  const formatName = (name) => {
    return name.replace("-", " ");
  };

  return (
    <div>
      <div className="card-columns">
        <div className="title">Categorías</div>
        {categories.map((e, i) => (
          <div
            className="category-card"
            key={e.category}
            onClick={() => goCategory(e.category)}
          >
            <img className="imagen" src={e.firstProd.image} />
            <h2 className="card-title">{formatName(e.category)}</h2>
          </div>
        ))}
      </div>
    </div>
  );
}
