import React from "react";
import '../Styles/Slider.css'

export default function Slider() {
    return (
        <div className="slider">
            <div className="images-container">
                <img src="https://i.ibb.co/3c8ZHPz/b-d-1.png" alt="" />
                <img src="https://i.ibb.co/7VgB5PB/b-d-2.png" alt="" />
                <img src="https://i.ibb.co/CBG4cyh/b-d-3.png" alt="" />
            </div>
        </div>
    );
}