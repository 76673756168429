import React from 'react';
import Icons from './Components/Icons';
import Slider from './Components/Slider';
import CategoriesList from './Components/CategoriesList';
import { navigateToUrl } from 'single-spa';

export default function Root(props) {
  if (!sessionStorage.getItem('logged')) {
    navigateToUrl("/")

  }
  return (
    <>
      {
        sessionStorage.getItem('logged') &&
        <>
          <Slider />
          <CategoriesList />
          <Icons />
        </>
      }
    </>
  )
}
