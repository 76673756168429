import React from 'react'
import '../Styles/Icons.css'

export default function Icons() {
    return (
        <div className='super-container'>
            <div className="container">
                <div className="cards">
                    <div className="icon pigIcon"></div>
                    <h3>Las mejores ofertas</h3>
                    <p>
                        Encontrarás las mejores ofertas y precios a la medida de tu bolsillo.
                    </p>
                </div>
                <div className="cards">
                    <div className="icon cardIcon"></div>
                    <div>
                        <h3>Compra online</h3>
                        <p>
                            Contamos con toda la seguridad para que realices tus compras online.
                        </p>
                    </div>
                </div>
                <div className="cards">
                    <div className="icon truckIcon"></div>
                    <div>
                        <h3>Entrega garantizada</h3>
                        <p>
                            Entrega rápida puerta a puerta, con toda la seguridad en tus pedidos.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}